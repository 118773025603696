.login-container{
    height: 100vh;
}
#loginForm{
    background: #fff;
    border-radius: 8px;
    font-family: "Ubuntu";
}
#loginForm .form-control{
    font-size: 	0.875rem;
    height: 42px;
    border-radius: 8px;
}

#loginForm .form-label, #loginForm .text-dark{ 
    font-size: 16px;
}

.btn.loginBtn{
    height: 48px;
    width: 80%;
    border-radius: 8px;
    margin-top: 15px;
    background-color: #169393;
    text-transform: uppercase;
    font-size: 20px;
    font-family: "Ubuntu-Bold";
}