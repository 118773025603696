 .header{
    font-family: var(--global-font-bold);
  }
  
  .form-control{
    border-radius: 0;
    box-shadow: 1px 1px #ced4d9;
    border-color: #EDF5F4;
    font-size: 0.9rem;
  }
  
  .form-control:focus{
    border-color: #EDF5F4;
    box-shadow: 0 0 0 0.25rem rgb(237 245 244 / 25%);
  }
  .form-label{
    margin-top: 0.5rem;
  }
  
  .form-label strong{
    color: darkred;
  }
  
  .btn-primary,.form-check-input:checked{
    background-color: var(--global-color-main);
    border-color: var(--global-color-main);
  }
  
  .btn:hover{ 
    background-color: var(--global-color-second);
    border-color: var(--global-color-second);
  }